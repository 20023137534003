import Axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { uniqueId } from "underscore";
import "./App.css";
import { UserListItem } from "./components/UserListItem";
Axios.defaults.baseURL = "https://api-prod.healthier-app.com/";
export interface StatData {
  email: string;
  dates: string[];
}

export const App = () => {
  const { isLoading, data } = useQuery("fetchAllUserStats", () =>
    Axios.get<string[]>("getUsersWithStats")
  );
  if (isLoading) return <div>Loading</div>;

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Healthier User Statistics</h2>
      <div className="root">
        {data?.data.sort().map((email) => (
          <UserListItem key={uniqueId()} email={email} />
        ))}
      </div>
    </div>
  );
};

export default App;
