import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { UserGraph } from "./UserGraph";
import { Active } from "./Active";
import { Nav } from "./components/Nav";
const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Nav />
        <Switch>
          <Route path="/" exact>
            <App />
          </Route>
          <Route path="/active" exact>
            <Active />
          </Route>
          <Route path="/user/:email" exact>
            <UserGraph />
          </Route>
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
