import React from "react";
import { useHistory } from "react-router";

export const Nav = () => {
  const history = useHistory();
  return (
    <div style={classes.nav}>
      <div onClick={() => history.push("/")} style={classes.navItem}>
        Statistics
      </div>
      <div onClick={() => history.push("/active")} style={classes.navItem}>
        Active
      </div>
    </div>
  );
};

const classes: { [name: string]: React.CSSProperties } = {
  nav: {
    display: "flex",
    justifyContent: "space-evenly",
    height: 100,
    alignItems: "center",
  },
  navItem: {
    width: 100,
    height: 30,
    fontSize: 22,
    border: "1px solid #5e92f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};
