import React from "react";
import { useHistory } from "react-router";

interface UserListItemProps {
  email: string;
}
export const UserListItem = ({ email }: UserListItemProps) => {
  const history = useHistory();
  return (
    <div className="item" onClick={() => history.push(`/user/${email}`)}>
      {email}
    </div>
  );
};
