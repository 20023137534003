import Axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { uniqueId } from "underscore";
import { UserListItem } from "./components/UserListItem";

const generateYears = () => {
  const years = [];
  for (let i = 2018; i <= moment().year(); i++) {
    years.push(i);
  }
  return years;
};
interface ActiveUserDTO {
  month: string;
  year: number;
}
const Months = () =>
  Array.apply(0, Array(12)).map(function (_, i) {
    return moment().month(i).format("MMM");
  });
export const Active = () => {
  const [date, setDate] = useState<ActiveUserDTO>({
    month: "Jan",
    year: 2020,
  });
  const activeUsers = useMutation((body: ActiveUserDTO) =>
    Axios.post("/activeUsers", body)
  );
  return (
    <div>
      <div>
        select month:{" "}
        <select
          value={date.year}
          onChange={(e) => setDate({ ...date, year: parseInt(e.target.value) })}
        >
          {generateYears().map((year) => (
            <option key={uniqueId()} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          value={date.month}
          onChange={(e) => setDate({ ...date, month: e.target.value })}
        >
          {Months().map((month) => (
            <option key={uniqueId()} value={month}>
              {month}
            </option>
          ))}
        </select>
        <button onClick={() => activeUsers.mutate(date)}>Submit</button>
      </div>
      <div>
        {activeUsers.isLoading ? (
          <div>loading</div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {activeUsers.data && (
              <div>
                <h3>Active Users</h3>
                {activeUsers.data.data.map((item: any) => (
                  <UserListItem email={item.user.email} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
