import Axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useRouteMatch } from "react-router";
import GoBackSvg from "./components/goback.svg";

import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
} from "recharts";
import { ReactSVG } from "react-svg";
const createLabels = (data?: string[]) => {
  if (data) {
    const allDates = enumerateDaysBetweenDates(data[0], data[data.length - 1]);
    return allDates.map((date) => ({
      x: date,
      y: data.filter((val) =>
        moment(val, "YYYY-MM-DD").isSame(moment(date, "DD/MM/YYYY"), "day")
      ).length,
    }));
  }
};
export const UserGraph = () => {
  const history = useHistory();
  const match = useRouteMatch<{ email: string }>();
  const [labels, setLabels] = useState<{ x: string; y: number }[]>();
  const { isLoading, data } = useQuery("fetchStats", () =>
    Axios.get<string[]>(`getStats/${match.params.email}`)
  );

  useEffect(() => {
    if (!isLoading) {
      console.log("here");
      const labels = createLabels(data?.data);
      setLabels(labels);
    }
  }, [data, isLoading]);

  if (isLoading) return <div>Loading</div>;
  return (
    <div style={{ height: "100vh" }}>
      <div style={{ display: "flex" }}>
        <div className="goback" onClick={() => history.goBack()}>
          <ReactSVG src={GoBackSvg} />
        </div>
        <h3 style={{ textAlign: "center", marginBottom: 70 }}>
          User: {match.params.email}
        </h3>
      </div>
      <div style={{ display: "flex" }}>
        {labels && (
          <ResponsiveContainer width="90%" height="50%" aspect={2}>
            <BarChart data={labels}>
              <Bar dataKey="y" fill="#1565c0" />
              <CartesianGrid stroke="#5e92f3" />
              <XAxis dataKey="x" />
              <YAxis domain={[0, 3]} />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

const enumerateDaysBetweenDates = function (
  startDate: string,
  endDate: string
) {
  if (startDate === endDate) {
    const dates = [];

    const currDate = moment(startDate).subtract(1, "day").startOf("day");
    const lastDate = moment(endDate).add(30, "days").startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().format("DD/MM/YYYY"));
    }

    return dates;
  } else {
    const dates = [];
    const currDate = moment(startDate).subtract(1, "day").startOf("day");
    const lastDate = moment(endDate).add(1, "days").startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().format("DD/MM/YYYY"));
    }

    return dates;
  }
};
